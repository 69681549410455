import axios from 'axios';
import AuthService from "./auth.service";
import {API_URL} from "./config";

class ScoreService {
  getSimilarityScoreByUserIdAndType(userId, type) {
    return axios.get(`${API_URL}/score/${userId}`, { headers: AuthService.getHeader(), params: { type } });
  }
}

export default new ScoreService();
