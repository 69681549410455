import React, { useCallback, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { AppBar, Box, Toolbar, Menu, Button, MenuItem, IconButton,
  CircularProgress, debounce, TextField, Badge } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import { makeStyles } from '@mui/styles';
import { styled } from "@mui/material/styles";
import { useUserContext } from "../App/App";
import AuthService from "../Services/auth.service";
import UserService from "../Services/user.service";
import Logo from "./Logo";

const useStyles = makeStyles({
  search: {
    position: 'relative',
    marginRight: '16px',
    marginLeft: 0,
    width: '100%',
    color: 'blue !important',
    '@media (min-width:600px)': {
      width: 'auto',
      marginLeft: '24px'
    }
  },
  inputRoot: {
    padding: '0 10px',
    borderRadius: '6px !important',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.25)',
    },
    color: '#efeff1 !important',
  },
  clear: {
    color: 'rgba(255, 255, 255, 0.58) !important'
  }
});

const CustomIconButton = styled(IconButton)({
  color: "white !important"
})

const HeadBar = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { user, setUser } = useUserContext();
  const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
  const [searchedUsers, setSearchedUsers] = React.useState([]);
  const loading = openAutocomplete && searchedUsers.length === 0;
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getOptionsDelayed = useCallback(
    debounce((text, callback) => {
      setSearchedUsers([]);
      if(text){
        UserService.searchUser(text)
          .then(callback)
          .catch(error => console.log(error))
      }
    }, 200),
    []
  );

  useEffect(() => {
    getOptionsDelayed(search, (response) => {
      setSearchedUsers(response.data)
    });
  }, [search, getOptionsDelayed]);

  const clearSearch = () => {
    setSearch("");
    setSearchedUsers([]);
  };

  useEffect(() => {
    clearSearch();
    selectedUser && navigate(`/profile/${selectedUser.id}`);
  }, [selectedUser]); // eslint-disable-line react-hooks/exhaustive-deps

  const toProfile = () => {
    handleMenuClose();
    navigate(`/profile/${user.id}`);
  };

  const toAccount = () => {
    handleMenuClose();
    navigate(`/account/${user.id}`);
  };

  const logout = () => {
    handleMenuClose();
    AuthService.logout(setUser);
    navigate(`/`);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={toProfile}>Profile</MenuItem>
      <MenuItem onClick={toAccount}>My account</MenuItem>
      <MenuItem onClick={logout}>Sign out</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <CustomIconButton aria-label="show 11 new notifications">
          <Badge badgeContent={11} color="secondary">
            <NotificationsIcon />
          </Badge>
        </CustomIconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <CustomIconButton aria-label="account of current user" aria-controls="primary-search-account-menu" aria-haspopup="true">
          <AccountCircleIcon />
        </CustomIconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div>
      <AppBar position="static" style={{ background: '#18181b' }}>
        <Toolbar>
          <Logo style={{cursor: "pointer"}} onClick={() => { clearSearch(); navigate(`/`) }} />
          { user &&
            <Autocomplete
              classes={{ root: classes.search, inputRoot: classes.inputRoot, clearIndicator: classes.clear }}
              id="searchBar"
              key={"searchBar"}
              style={{ width: 300 }}
              open={openAutocomplete}
              onOpen={() => {
                setOpenAutocomplete(true);
              }}
              onClose={() => {
                setOpenAutocomplete(false);
              }}
              isOptionEqualToValue={(option, value) => option.username === value.username}
              getOptionLabel={(option) => option.username}
              onChange={(_event, newValue) => setSelectedUser(newValue)}
              options={searchedUsers}
              loading={loading}
              freeSolo
              onInputChange={(_e, newInputValue) => setSearch(newInputValue)}
              renderInput={(params) => (
                <div style={{display: 'flex' }}>
                <SearchIcon style={{ position: 'absolute', bottom: '4px', left: '6px', color: 'rgba(255, 255, 255, 0.58)' }}/>
                <TextField
                  {...params}
                  label="Search..."
                  InputProps={{
                    ...params.InputProps,
                    style: {
                      padding: '0 40px'
                    },
                    disableUnderline: true,
                    endAdornment: (
                      <React.Fragment>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  InputLabelProps={{
                    style: { color: 'rgba(255, 255, 255, 0.58)', paddingLeft: '40px' },
                  }}
                />
                </div>
              )}
            />
          }
          { user ?
            <>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }} style={{ marginLeft: 'auto' }}>
                <CustomIconButton aria-label="show 17 new notifications">
                  <Badge badgeContent={1} color="secondary">
                    <NotificationsIcon />
                  </Badge>
                </CustomIconButton>
                <CustomIconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  >
                  <AccountCircleIcon />
                </CustomIconButton>
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }} style={{ marginLeft: 'auto' }}>
                <IconButton
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </> :
            <div style={{ marginLeft: 'auto' }}>
              <Link to ="/login" style={{ textDecoration: "none" }}>
                <Button color="secondary">
                  Login
                </Button>
              </Link>
              <Link to="/register" style={{ textDecoration: "none" }}>
                <Button variant="outlined" color="secondary">
                  Sign up
                </Button>
              </Link>
            </div>
          }
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};
export default HeadBar;