import React from "react";
import { BrowserRouter, Outlet, Route, Routes, Navigate } from 'react-router-dom';
import HeadBar from "../Header/HeadBar";
import Footer from "../Footer/Footer";
import Home from "../Home/Home";
import Login from "../Connexion/Login";
import Register from "../Connexion/Register";
import Profile from "../Profile/Profile";
import { styled } from "@mui/material/styles";
import Account from "../Account/Account";
import AuthService from "../Services/auth.service";

const Wrapper = styled('div')({
  width: "100%",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

function ProtectedRoute({ isAuthenticated, children }) {
  if (!isAuthenticated) {
    return <Navigate to={"/login"} replace />;
  }

  return children ? children : <Outlet />
}

const PageLayout = () => (
  <Wrapper >
    <HeadBar />
    <Outlet />
    <Footer />
  </Wrapper>
);

const Routing = () => {
  return(
    <BrowserRouter>
      <Routes>
        <Route element={<PageLayout />}>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />}/>
          <Route path="/register" element={<Register />}/>
          <Route element={<ProtectedRoute isAuthenticated={AuthService.isAuthenticated()}/>}>
            <Route path="/profile/:userId" element={<Profile />} />
            <Route path="/account/:userId" element={<Account />}/>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
