import React, {useState} from "react";
import { styled } from "@mui/material/styles";
import { Modal, Backdrop, Fade, TextField, Button, CircularProgress, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const CustomModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})
const CustomCloseIcon = styled(CloseIcon)({
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer",
})
const Wrapper = styled('div')({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "relative",
  height: "280px",
  width: "400px",
  backgroundColor: "white",
  border: "1px solid gray",
  borderRadius: "6px",
  padding: "16px 32px 24px",
  boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)"
})

const PasswordModal = (props) => {
  const [password, setPassword] = useState("");

  return(
    <CustomModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.isOpen}
      onClose={() => { setPassword(""); props.setPasswordModalOpen(false) }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.isOpen}>
        <Wrapper>
          <h2 style={{textAlign: "center", marginBottom: 0}}>Your current password</h2>
          <Divider />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            type="password"
            id="password"
            label="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button variant="contained" color="secondary" disabled={!password} onClick={() => { setPassword(""); props.onCheckPassword(password) } }>
            <span style={{ marginRight: 10 }}>Confirm</span> 
            {props.loading && <CircularProgress color="inherit" size={20} />}
          </Button>
          <CustomCloseIcon onClick={() => { setPassword(""); props.setPasswordModalOpen(false) } } />
        </Wrapper>
      </Fade>
    </CustomModal>
  );
};

export default PasswordModal;
