import React, { useEffect, useState } from "react";
import profileLogo from "../Assets/avatar.png";
import { useUserContext } from "../App/App";
import { toast } from "react-toastify";
import { Button, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";
import AccountBox from '@mui/icons-material/AccountBox';
import FileService from "../Services/file.service";
import UserService from "../Services/user.service";
import AuthService from "../Services/auth.service";
import PasswordModal from "./PasswordModal";

const Wrapper = styled('div')({
  margin: "10px auto",
  position: "relative",
  display: "flex",
  flex: 1,
  flexDirection: "column",
});
const Background = styled('div')({
  width: "100%",
  height: "70px",
});
const Content = styled('div')({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  borderRadius: "6px",
  alignItems: "center",
  padding: "50px 20px 0 20px",
  "& > *": {
    margin: "20px 0",
  }
});
const Avatar = styled('img')({
  position: "absolute",
  objectFit: "cover",
  width: "140px",
  height: "140px",
  left: 0,
  right: 0,
  margin: "auto",
  borderRadius: "50%",
});

const Account = () => {
  const { user, setUser } = useUserContext();

  const [avatar, setAvatar] = React.useState(null);
  const [username, setUsername] = React.useState(user.username);
  const [email, setEmail] = React.useState(user.email);
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordModalOpen, setPasswordModalOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    FileService.getFile(user.avatar)
    .then(resp => setAvatar(URL.createObjectURL(resp.data)))
    .catch(err => console.log(err))
  }, [user.avatar]);

  const onSendAvatar = (file) => {
    UserService.setAvatar(user.id, file)
      .then(
        resp => {
          toast.success(`Avatar successfully updated !`);
          localStorage.setItem("user", JSON.stringify({ ...JSON.parse(localStorage.getItem('user')), avatar: resp.newAvatar }));
          setUser({ ...user, avatar: resp.newAvatar });
        },
        () => toast.error(`Failed to update the avatar !`)
    )
  }

  const onUpdateUser = () => {
    UserService.updateUser(user.id, username, email, newPassword)
      .then(
        updatedUser => {
          setLoading(false);
          setPasswordModalOpen(false);
          toast.success(`User successfully updated !`);
          localStorage.setItem("user", JSON.stringify({ ...JSON.parse(localStorage.getItem('user')), ...updatedUser.data }));
          setUser({...user, ...updatedUser.data});
        },
        error => { 
          setLoading(false);
          setPasswordModalOpen(false);
          toast.error(`Failed to update the user, ${error.response.data.message} !`);
        }
      )
  }

  const onCheckPassword = (password) => {
    setLoading(true);
    AuthService.checkPassword(password)
      .then(
        () => onUpdateUser(),
        error => {
          setLoading(false);
          toast.error(`Failed to update the user, ${error.response.data.message} !`);
        }
      )
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if(newPassword && newPassword === confirmPassword){
      setPasswordModalOpen(true);
    }
    else {
      toast.error(`Make sure password and confirmation password are the same !`);
    }
  }

  return(
    <Wrapper>
      <Avatar src={avatar ? avatar : profileLogo} alt={"Profile"} />
      <Background />
      <Content>
        <form onSubmit={(e) => onSubmit(e)} noValidate style={{ maxWidth: "600px" }}>
          <Button
            variant="outlined"
            component="label"
            color="secondary"
            style={{ display: "flex", margin: "10px auto", width: "fit-content" }}
          >
            Upload Avatar <AccountBox style={{ marginLeft: "5px" }}/>
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={ (e) => onSendAvatar(e.target.files[0]) }
            />
          </Button>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="name"
            label="Name"
            name="name"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="email"
            label="Email"
            type="email"
            id="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="New Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            name="password"
            label="Confirm New Password"
            type="password"
            id="confirm-password"
            autoComplete="current-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            style={{ marginTop: "24px" }}
          >
            Save
          </Button>
        </form>
      </Content>
      <PasswordModal
        key={user.id}
        isOpen={passwordModalOpen}
        loading={loading}
        setPasswordModalOpen={setPasswordModalOpen}
        onCheckPassword={onCheckPassword} />
    </Wrapper>
  );
};

export default Account;
