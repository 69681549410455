import React from "react";
import { styled } from "@mui/material/styles";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

const size = {
  xs: '320px',
  sm: '768px',
  lg: '1200px',
}
export const device = {
  xs: `(min-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  lg: `(min-width: ${size.lg})`
}

export const getColorFromPercent = (value) => {
  const hue=((value/100)*120).toString(10);
  return ["hsl(",hue,",100%,50%)"].join("");
}

export const Divider = styled('span')({
  height: "2px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 2px",
  display: "table-cell",
  width: "70%",
  backgroundImage: "linear-gradient(90deg, transparent, grey, transparent)"
})

export const ContentLayout = (props) => (
  <div style={{
    display: "flex",
    width: "100%",
    minHeight: "calc(100vh - 264px)",
    backgroundColor: "#e9ebee"
  }}>
    {props.children}
  </div>
)

export const ConfirmDialog = ({isOpen, title = "Are you sure to delete this ranking ?", onCancel, onConfirm}) => {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogActions>
        <Button variant="contained" color="neutral" onClick={onCancel}>no</Button>
        <Button variant="contained" color="secondary" onClick={onConfirm}>yes</Button>
      </DialogActions>
    </Dialog>
  )
}