import React, {createContext, useContext} from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Routing from "../Routing/Router";
import {ToastContainer} from "react-toastify";

const USER = JSON.parse(localStorage.getItem('user'));

const theme = createTheme({
  palette: {
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#e4202e",
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});

export const UserContext = createContext(null);
export const useUserContext = () => useContext(UserContext);

function App() {
  const [user, setUser] = React.useState(USER);

  return (
    <UserContext.Provider value={{user, setUser}}>
      <ThemeProvider theme={theme}>
        <Routing />
        <ToastContainer />
      </ThemeProvider>
    </UserContext.Provider>
  );
}

export default App;
