import React from "react";
import { styled } from "@mui/material/styles";
import MovieFilterIcon from '@mui/icons-material/MovieFilter';

const Wrapper = styled('div')({
  display: "flex",
  flexDirection: "column",
});
const Top = styled('div')({
  position: "relative",
  height: "26px",
});
const LeftBar = styled('div')(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: 0,
  width: "30%",
  borderBottom: `3px solid ${theme.palette.secondary.main}`,
}));
const RightBar = styled('div')(({ theme }) => ({
  position: "absolute",
  top: "50%",
  right: 0,
  width: "30%",
  borderBottom: `3px solid ${theme.palette.secondary.main}`,
}));
const Title = styled('h3')(({ theme }) => ({
  margin: 0,
  color: theme.palette.secondary.main,
}));
const CustomMovieFilterIcon = styled(MovieFilterIcon)(({ theme }) => ({
  color: theme.palette.secondary.main,
  position: "absolute",
  left: 0,
  right: 0,
  margin: "auto",
}));

const Logo = (props) => {
  return(
    <Wrapper {...props}>
      <Top>
        <CustomMovieFilterIcon fontSize="large"/>
        <LeftBar/>
        <RightBar/>
      </Top>
      <Title>PopProfiles</Title>
    </Wrapper>
  );
};

export default Logo;
