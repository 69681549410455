import React, {useCallback, useEffect, useState} from "react";
import {toast} from "react-toastify";
import { styled } from "@mui/material/styles";
import { Modal, Backdrop, Fade, TextField, CircularProgress, Button, Divider } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import debounce from "@mui/utils/debounce";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  CircularInput,
  CircularTrack,
  CircularProgress as ReactCircularProgress,
  CircularThumb
} from 'react-circular-input'
import filmlogo from "../Assets/film.jpg";
import RankingService from "../Services/ranking.service";
import { ConfirmDialog } from "../Utils/ui";

const CustomModal = styled(Modal)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
})
const CustomCloseIcon = styled(CloseIcon)({
  position: "absolute",
  top: "10px",
  right: "10px",
  cursor: "pointer"
})
const Wrapper = styled('div')({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "relative",
  width: "500px",
  backgroundColor: "white",
  border: "1px solid gray",
  borderRadius: "6px",
  padding: "16px",
  boxShadow: "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%)",
  '@media (max-width:575px)': {
    width: "100vw",
  }
})
const Ranker = styled('div')({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  marginBottom: "20px"
})
const AutocompleteOption = styled('div')({
  display: "flex"
})
const AutocompleteOptionPoster = styled('img')({
  height: "50px"
})
const AutocompleteSelectedPoster = styled('img')({
  border: "2px dashed #00000026"
})

const RankModal = ({selectedResource, ...props}) => {
  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState({});
  const [percent, setPercent] = useState(0.75);
  const [comment, setComment] = useState("");
  const [confirmDialogIsOpen, setConfirmDialogIsOpen] = useState(false);
  const loading = openAutocomplete && options.length === 0;

  const type = props.selectedField.type;

  useEffect(() => {
    if (selectedResource) {
      setPercent(selectedResource.grade / 100);
      setComment(selectedResource.comment);
      setSelectedValue(selectedResource.details);
    }
  }, [selectedResource]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getOptionsDelayed = useCallback(
    debounce((text, callback) => {
      setOptions([]);
      if(text !== ""){
        RankingService.getEntityBySearch(text, type)
          .then(callback)
          .catch(error => alert(error))
      }
    }, 200),
    []
  );

  const onNewRank = () => {
    RankingService.setRanking(selectedValue.id, Math.round(percent * 100), type, comment)
      .then(
        () => onClose(),
        () => toast.error(`Failed to rank this ${type} !`)
      )
  }

  const onDeleteRank = () => {
    RankingService.deleteRanking(selectedValue.id)
      .then(
        () => {setConfirmDialogIsOpen(false); onClose()},
        () => toast.error(`Failed to delete this ${type} ranking !`)
      )
  }

  useEffect(() => {
    getOptionsDelayed(inputValue, (response) => {
      setOptions(response.data)
    });
  }, [inputValue, getOptionsDelayed]);

  const onClose = () => {
    setSelectedValue({});
    setPercent(0.75);
    setComment("");
    props.onDismiss();
  }

  return(
    <CustomModal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.isOpen}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.isOpen}>
        <Wrapper>
          <div style={{ display: "flex" }}>
            {selectedResource && <Button size="small" variant="outlined" color="secondary" onClick={() => setConfirmDialogIsOpen(true)} style={{ position: "fixed" }}>Delete <DeleteIcon /></Button>}
            <h2 style={{margin: 0, flexGrow: 1, textAlign: "center"}}>Rank a {type}</h2>
          </div>
          <Divider style={{margin: "20px 0 20px 0"}} />
          <Ranker>
              {
                selectedResource ?
                <h3 style={{margin: 0}}>{selectedValue.title}</h3>:
                <Autocomplete
                  id={`${type}-autocomplete`}
                  key={`${type}-autocomplete`}
                  open={openAutocomplete}
                  onOpen={() => {setOpenAutocomplete(true);}}
                  onClose={() => {setOpenAutocomplete(false);}}
                  getOptionSelected={(option, value) => type === "serie" ? option.name === value.name : option.title === value.title}
                  getOptionLabel={(option) => type === "serie" ? option.name : option.title}
                  onChange={(_event, newValue) => {setSelectedValue(newValue);}}
                  options={options}
                  renderOption={(props, option) => (
                      <AutocompleteOption {...props}>
                        <AutocompleteOptionPoster src={`https://image.tmdb.org/t/p/w92/${option.poster_path}`} alt={"Poster"}/>
                        {type === "serie" ? option.name : option.title}
                      </AutocompleteOption>
                    )
                  }
                  loading={loading}
                  onInputChange={(_e, newInputValue) => setInputValue(newInputValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={`${type} name`}
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              }
              {
                Object.keys(selectedValue).length > 0 &&
                <div style={{display: "flex", justifyContent: "space-around", padding: "10px 0"}}>
                  <CircularInput radius={50} value={percent} onChange={setPercent} style={{margin: "20px 0"}}>
                    <CircularTrack strokeWidth={10}/>
                    <ReactCircularProgress stroke={`hsl(${percent * 100}, 100%, 50%)`} strokeWidth={10}/>
                    <CircularThumb
                      r={10}
                      fill={`hsl(${percent * 100}, 100%, 40%)`}
                      stroke={`hsl(${percent * 100}, 100%, 40%)`}
                    />
                    <text x={50} y={50} textAnchor="middle" dy="0.3em" fontWeight="bold">
                      {`${Math.round(percent * 100)} %`}
                    </text>
                  </CircularInput>
                  <AutocompleteSelectedPoster src={Object.keys(selectedValue).length === 0 ? filmlogo: `https://image.tmdb.org/t/p/w92/${selectedValue.poster_path}`} alt="test"/>
                </div>
              }
              {
                Object.keys(selectedValue).length > 0 &&
                <TextField
                  label="Comment"
                  style={{width: "100%"}}
                  inputProps={{ maxLength: 60 }}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}/>
              }
          </Ranker>
          <Button variant="contained" color="secondary" disabled={Object.keys(selectedValue).length === 0} onClick={onNewRank}>Rank !</Button>
          <CustomCloseIcon onClick={onClose} />
          <ConfirmDialog isOpen={confirmDialogIsOpen} onCancel={() => setConfirmDialogIsOpen(false)} onConfirm={onDeleteRank}/>
        </Wrapper>
      </Fade>
    </CustomModal>
  );
};

export default RankModal;
