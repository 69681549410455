import axios from 'axios';
import AuthService from "./auth.service";
import {API_URL} from "./config";

class FileService {
  getFile(fileId) {
    return axios.get(`${API_URL}/file/${fileId}`, { headers: AuthService.getHeader(), responseType: 'blob' })
  };

  setFile(file) {
    return new Promise((resolve, reject) => {
      if(file.size > 5000000) {
        throw new Error("SizeLimit");
      }

      const formData = new FormData();
      formData.append('file', file);

      return axios.post(
        `${API_URL}/file`,
        formData,
        { headers: { ...AuthService.getHeader(), 'Content-Type': 'multipart/form-data' } }
      )
    });
  }
}

export default new FileService();
