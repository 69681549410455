import React from "react";
import { styled } from "@mui/material/styles";
import {toast} from "react-toastify";
import { Avatar, Button, CssBaseline, TextField, Grid, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {Link} from "react-router-dom";
import {ContentLayout} from "../Utils/ui";
import AuthService from "../Services/auth.service";
import { useNavigate, useLocation } from "react-router-dom";
import {useUserContext} from "../App/App";

const Wrapper = styled('div')({
  display: "flex",
  flexDirection: "column",
  width: "400px",
  alignItems: "center",
  margin: "auto",
  padding: "30px",
  borderRadius: "6px",
  backgroundColor: "white"
})

const Login = () => {
  const location = useLocation();
  const { setUser } = useUserContext();
  const theme = useTheme();
  const navigate = useNavigate();
  const [email, setEmail] = React.useState(location.state?.email && location.state?.email);
  const [password, setPassword] = React.useState(null);
  
  const onLogin = (e) => {
    e.preventDefault();
    AuthService.login(email, password, setUser)
      .then((user) => { navigate(`/profile/${user.id}`) },
      error => toast.error(`${error.response.data.message} !`)
    )
  }

  return(
    <ContentLayout>
      <Wrapper>
        <CssBaseline />
        <Avatar style={{backgroundColor: theme.palette.secondary.main}}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form onSubmit={(e) => onLogin(e)} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            style={{ margin: "24px 0px 16px" }}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to={"/"} variant="body2">
                Forgot password ?
              </Link>
            </Grid>
            <Grid item>
              <Link to={"/register"} variant="body2">
                {"No account ? Sign Up."}
              </Link>
            </Grid>
          </Grid>
        </form>
      </Wrapper>
    </ContentLayout>
  );
};

export default Login;
