import React, {useEffect, useState} from "react";
import Carousel from "react-multi-carousel";
import { styled } from "@mui/material/styles";
import { keyframes } from "@emotion/react";
import ScoreService from "../Services/score.service";
import './styles.css';

const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1920 },
    items: 4,
    slidesToSlide: 1
  },
  desktop: {
    breakpoint: { max: 1919, min: 576 },
    items: 3,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 2,
    slidesToSlide: 1
  }
};

const progressKeyframe = (value) => keyframes`
    to { --progress-value: ${value}; }
`;

const SimilarityScore = styled('h1')(({ theme }) => ({
  fontSize: "calc(8px + 2vw)",
  fontFamily: "ui-rounded",
  alignSelf: "center",
  color: theme.palette.secondary.main
}));
const ProgressWrapper = styled('div')(({ theme, similarityScore }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "150px",
  height: "150px",
  borderRadius: "50%",
  zIndex: 1,
  background: `radial-gradient(closest-side, white 79%, transparent 80% 100%), conic-gradient(${theme.palette.secondary.main} calc(var(--progress-value) * 1%), #f3a2a8 0)`,
  animation: `${progressKeyframe(similarityScore)} 2s 1 forwards`,
  '&::before': {
    fontSize: "40px",
    counterReset: "percentage var(--progress-value)",
    content: "counter(percentage) '%'",
    animation: `${progressKeyframe(similarityScore)} 2s 1 forwards`
  }
}));
const Avatar = styled('img')({
  objectFit: "cover",
  width: "80px",
  height: "80px",
  left: 0,
  right: 0,
  margin: "auto",
  borderRadius: "50%"
});
const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  width: "100%",
  '@media (max-width:775px)': {
    flexDirection: 'column',
  }
});
const LeftWrapper = styled('div')({
  display: 'flex',
  width: '40%',
  justifyContent: 'center',
  '@media (max-width:775px)': {
    width: '100%',
  }
});

const FriendProfile = ({rankings, selectedField, currentUser, currentUserAvatar, myAvatar}) => {
  const [width] = useState(window.innerWidth);
  const [similarityScore, setSimilarityScore] = useState(null);

  useEffect(() => {
    async function fetchSimilarityScore() {
      const { data } = await ScoreService.getSimilarityScoreByUserIdAndType(currentUser.id, selectedField.type)
      setSimilarityScore(data)
    }
    fetchSimilarityScore();
  }, [currentUser, selectedField]);

  const getSlideNumber = (width) => {
    if(width >= 576) return 3;
    if(width >= 464) return 2;
    return 1;
  }

  return(
    <Wrapper>
      <LeftWrapper>
        {similarityScore ?
        <div style={{ display: "flex", flexDirection: "column"}}>
          <div style={{ display: "flex", alignItems: "center"}}>
            <Avatar src={myAvatar} alt={"Profile"} style={{ marginRight: -10 }}/>
            <ProgressWrapper similarityScore={similarityScore.score}>
              <progress value={`${similarityScore.score}`} min="0" max="100" style={{visibility:"hidden", height: 0, width: 0}}>{`${similarityScore.score}%`}</progress>
            </ProgressWrapper>
            <Avatar src={currentUserAvatar} alt={"Profile"} style={{ marginLeft: -10 }}/>
          </div>
          <h2 style={{ textAlign: "center" }}>{`Based on ${similarityScore.number} ${selectedField.name} in common`}</h2>
        </div>
        :
          <SimilarityScore>
            {`You do not have ranked ${selectedField.name} in common with ${currentUser.username.charAt(0).toUpperCase()}${currentUser.username.slice(1)}`}
          </SimilarityScore>
        }
      </LeftWrapper>
        <Carousel
          responsive={responsive}
          swipeable={true}
          draggable={false}
          showDots={rankings.length >= getSlideNumber(width)} // TODO: WHEN FIXED => TRUE
          infinite={false}
          autoPlay={false}
          ssr={true}
          shouldResetAutoplay={false}
          keyBoardControl={true}
          customTransition="all .5s"
          transitionDuration={100}
          renderButtonGroupOutside={true}
          containerClass="friend-carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          dotListClass="carousel-dot-list"
          itemClass="carousel-item-padding-40-px"
        >
          {rankings ? rankings.map(rank => <selectedField.render key={rank.id} isMe={true} type={selectedField.type} rank={rank} />) : <div />}
        </Carousel>
    </Wrapper>
  );
};

export default FriendProfile;
