import axios from 'axios';
import AuthService from "./auth.service";
import {API_URL} from "./config";

class UserService {
  getUser(userId) {
    return axios.get(`${API_URL}/user/${userId}`, { headers: AuthService.getHeader() })
  };

  setAvatar(userId, file) {
    return new Promise((resolve, reject) => {
      if(file.size > 5000000) {
        throw new Error("SizeLimit");
      }

      const formData = new FormData();
      formData.append('avatar', file);

      axios.put(
        `${API_URL}/user/${userId}/avatar`,
        formData,
        { headers: { ...AuthService.getHeader(), 'Content-Type': 'multipart/form-data' } }
      )
        .then(response=> resolve(response.data))
        .catch(err => reject(err))
    });
  }

  updateUser(userId, username, email, password) {
    return axios.put(
      `${API_URL}/user/${userId}`,
      {
        username,
        email,
        password
      },
      { headers: AuthService.getHeader() }
    );
  }

  searchUser(search) {
    return axios.get(`${API_URL}/user/search`, { headers: AuthService.getHeader(), params: { search } });
  }
}

export default new UserService();
