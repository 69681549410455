import React from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, TextField } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import homeLogo from '../Assets/homeLogo.png';
import {useUserContext} from "../App/App";

const Wrapper = styled('div')({
  display: "flex",
  flex: 1,
  width: "100%",
  backgroundColor: "white",
  padding: "40px",
  boxSizing: "border-box",
  justifyContent: "space-between",
  "@media only screen and (max-width: 1200px)": {
    flexDirection: "column-reverse",
    padding: "10px",
  }
})
const Side = styled('div')({
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  width: "50%",
  "@media only screen and (max-width: 1200px)": {
    width: "100%"
  }
})
const Image = styled('img')({
  maxHeight: "100%",
  objectFit: "cover",
  objectPosition: "center"
})
const Title = styled('h1')(({ theme }) => ({
  margin: 0,
  fontSize: "calc(8px + 5vw)",
  fontFamily: "ui-rounded",
  color: theme.palette.secondary.main
}));
const GettingStarted = styled('div')({
  display: "flex",
  marginTop: "24px"
})
const GoToButton = styled(Button)({
  width: '50%',
  boxShadow: 'none',
  "@media only screen and (max-width: 575px)": {
    width: "100%"
  }
})

const Home = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [email, setEmail] = React.useState(null);

  return(
    <Wrapper>
      <Side>
        <Image src={homeLogo} alt={"crowd"}/>
      </Side>
      <Side>
        <Title>Where Pop Culture Reigns</Title>
        <h2 style={{ fontFamily: 'system-ui' }}>Rank your movies and series, share your new findings, get your friends' PopProfile, compare yourself with the global PopProfile ranking.</h2>
        { user ?
          <GoToButton
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => { navigate(`/profile/${user.id}`) }}
          >
            GO TO YOUR PROFILE <KeyboardArrowRightIcon />
          </GoToButton>:
          <GettingStarted>
            <TextField
              style={{flex: 1, margin: '0 10px 0 0'}}
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              style={{ flex: 0.6, boxShadow: 'none' }}
              onClick={() => navigate({
                to: '/register',
                state: { email }
              })}
            >
              GET STARTED
            </Button>
          </GettingStarted>
        }
        {!user &&
          <Link to={"/login"} variant="body2">
            <p>
              {"Already have an account ? Sign in."}
            </p>
          </Link>
        }
      </Side>
    </Wrapper>
  );
};

export default Home;
