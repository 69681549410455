import React from "react";
import { styled } from "@mui/material/styles";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import Divider from "@mui/material/Divider";

const Wrapper = styled('div')({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  height: "100px",
  boxSizing: "border-box",
  backgroundColor: "#1d2331"
})
const Content = styled('div')({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  margin: "16px 0",
  height: "20px",
  backgroundColor: "#1d2331",
  color: "white",
  "& > *": {
    margin: "0 10px"
  }
})
const Copyrights = styled('div')({
  display: "flex",
  justifyContent: "center",
  margin: "16px 0",
  height: "20px"
})

const Footer = () => {
  return(
    <Wrapper>
      <Content>
        <FacebookIcon style={{ cursor: 'pointer' }}/>
        <TwitterIcon style={{ cursor: 'pointer' }}/>
        <InstagramIcon style={{ cursor: 'pointer' }}/>
      </Content>
      <Divider style={{ background: '#ffffff2e' }} variant="middle" />
      <Copyrights>
        <span style={{ color: "grey" }}>© 2023 Copyrights</span>
        <span style={{ color: "white", marginLeft: "10px" }}>popprofiles.florentin-besset.com</span>
      </Copyrights>
    </Wrapper>
  );
};

export default Footer;
