import React, {useState} from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Carousel from "react-multi-carousel";
import './styles.css';

const responsive = {
  largeDesktop: {
    breakpoint: { max: 4000, min: 1920 },
    items: 6,
    slidesToSlide: 1
  },
  desktop: {
    breakpoint: { max: 1919, min: 1200 },
    items: 5,
    slidesToSlide: 1
  },
  tablet: {
    breakpoint: { max: 1199, min: 768 },
    items: 4,
    slidesToSlide: 1
  },
  phablet: {
    breakpoint: { max: 767, min: 576 },
    items: 3,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 2,
    slidesToSlide: 1
  }
};

const RankButton = styled(Button)(({ theme }) => ({
  display: "flex",
  alignSelf: "flex-start",
  minWidth: "150px !important",
  marginLeft: "40px !important",
  color: "white !important",
  justifyContent: "space-between !important",
  backgroundColor: `${theme.palette.secondary.main} !important`
}));

const MyProfile = ({rankings, selectedField, openRankModal}) => {
  const [width] = useState(window.innerWidth);

  const getSlideNumber = (width) => {
    if(width >= 1024) return 6;
    if(width >= 464) return 2;
    return 1;
  }

  return(
    <>
      <RankButton variant="contained" onClick={() => openRankModal(true)}>
        Rank a {selectedField.type} <AddCircleIcon style={{marginLeft: "10px"}}/>
      </RankButton>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={false}
        showDots={rankings.length >= getSlideNumber(width)} // TODO: WHEN FIXED => TRUE
        infinite={false}
        autoPlay={false}
        ssr={true}
        shouldResetAutoplay={false}
        keyBoardControl={true}
        customTransition="all .5s"
        transitionDuration={100}
        renderButtonGroupOutside={true}
        renderDotsOutside={true}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="carousel-dot-list"
        itemClass="carousel-item-padding-40-px"
      >
        {rankings ? rankings.map(rank => <selectedField.render key={rank.id} isMe={true} type={selectedField.type} rank={rank} clickOnCard={() => openRankModal(true, rank)} />) : <div />}
      </Carousel>
    </>
  );
};

export default MyProfile;
