import axios from 'axios';
import AuthService from "./auth.service";
import {API_URL} from "./config";

class RankingService {
  getRankingByUserIdAndType(userId, type) {
    return axios.get(`${API_URL}/ranking/${userId}`, { headers: AuthService.getHeader(), params: { type } });
  }

  getEntityBySearch(query, type) {
    return axios.get(`${API_URL}/ranking/search`, { headers: AuthService.getHeader(), params: { query, type } });
  }

  setRanking(resourceId, grade, type, comment) {
    return axios.post(
      `${API_URL}/ranking`,
      {
        resourceId,
        grade,
        type,
        comment
      },
      { headers: AuthService.getHeader()}
    );
  }

  deleteRanking(resourceId) {
    return axios.delete(`${API_URL}/ranking`, { headers: AuthService.getHeader(), data: { resourceId } });
  }
}

export default new RankingService();
