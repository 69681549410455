import React from "react";
import { styled } from "@mui/material/styles";
import { getColorFromPercent } from "../Utils/ui";

const FlipCardInner = styled('div')({
  position: "relative",
  width: "100%",
  height: "225px",
  textAlign: "center",
  transition: "transform 0.6s",
  transformStyle: "preserve-3d"
})
const FlipCard = styled('div')((props) => ({
  backgroundColor: "transparent",
  width: "150px",
  height: "225px",
  perspective: "1000px",
  cursor: props.isMe && "pointer",
}))
const FlipCardFront = styled('div')({
  position: "absolute",
  width: "100%",
  height: "100%",
  "-webkit-backface-visibility": "hidden",
  backfaceVisibility: "hidden",
  borderRadius: "6px",
  overflow: "hidden",
  boxShadow: "5px 5px 10px 0px rgba(150,170,180,0.5)",
})
const FlipCardBack = styled(FlipCardFront)({
  padding: "5px",
  boxSizing: "border-box",
  backgroundColor: "#29558a",
  color: "white",
  transform: "rotateY(180deg)",
  overflowWrap: "break-word"
})
const Bottom = styled('div')({
  textAlign: "center",
  "& > *": {
    margin: "0"
  },
  "& > h2": {
    marginTop: "-20px"
  }
})
const CircleBorder = styled('div')({
  fontSize: "20px",
  top: "-20px",
  left: "10px",
  position: "relative",
  padding: 0,
  width: "2em",
  height: "2em",
  backgroundColor: "#F2E9E1",
  borderRadius: "50%",
  lineHeight: "2em",
  display: "block",
  "&:after": {
    border: "none",
    position: "absolute",
    top: "0.20em",
    left: "0.20em",
    textAlign: "center",
    display: "block",
    borderRadius: "50%",
    width: "1.6em",
    height: "1.6em",
    backgroundColor: "#081c22",
    content: '" "'
  }
})
const Circle = styled('div')((props) => ({
  borderRadius: "50%",
  width: "2em",
  height: "2em",
  position: "absolute",
  clip: props.percent < 50 ? "rect(0, 2em, 2em, 1em)" : "rect(auto,auto,auto,auto)"
}))
const First50Bar = styled('div')((props) => ({
  display: props.percent < 50 && "none",
  position: "absolute",
  clip: "rect(0, 2em, 2em, 1em)",
  backgroundColor: getColorFromPercent(props.percent),
  borderRadius: "50%",
  width: "2em",
  height: "2em"
}))
const ValueBar = styled('div')((props) => ({
  position: "absolute",
  clip: "rect(0, 1em, 2em, 0)",
  width: "2em",
  height: "2em",
  borderRadius: "50%",
  border: `0.2em solid ${getColorFromPercent(props.percent)}`,
  /* background-color: #4D642D; */
  boxSizing: "border-box",
  transform: `rotate(${props.percent * 3.6}deg)`
}))
const PercentText = styled('div')({
  width: "1.5em",
  height: "1.5em",
  borderRadius: "50%",
  lineHeight: "1em",
  textAlign: "center",
  marginTop: "9px",
  marginLeft: "5px",
  position: "absolute",
  zIndex: 100,
  fontWeight: 700,
  color: "white"
})

export const ResourceCard = ({ type, rank, clickOnCard = null }) => (
  <div style={{ width: "150px" }} onClick={clickOnCard && clickOnCard}>
    <FlipCard isMe={clickOnCard}>
      <FlipCardInner>
        <FlipCardFront>
          <img src={`https://image.tmdb.org/t/p/w154/${rank.details.poster_path}`} alt={"Poster"} style={{ width: "100%", height: "100%"}}/>
        </FlipCardFront>
        <FlipCardBack>
          <p>{rank.comment}</p>
        </FlipCardBack>
      </FlipCardInner>
      <Bottom>
        <CircleBorder>
          <PercentText>{rank.grade}</PercentText>
          <Circle percent={rank.grade}>
            <First50Bar percent={rank.grade} />
            <ValueBar percent={rank.grade} />
          </Circle>
        </CircleBorder>
      </Bottom>
    </FlipCard>
    <div>
      <h4>{type === "movie" ? rank.details.title : rank.details.name}</h4>
    </div>
  </div>
);
