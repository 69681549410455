import React, {useEffect, useState} from "react";
import { styled, useTheme } from "@mui/material/styles";
import profileLogo from "../Assets/avatar.png";
import MovieIcon from '@mui/icons-material/Movie';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import { Divider } from "../Utils/ui";
import { isMe } from "../Utils/methods";
import RankingService from "../Services/ranking.service";
import { useParams } from 'react-router-dom';
import {useUserContext} from "../App/App";
import RankModal from "./RankModal";
import {toast} from "react-toastify";
import FileService from "../Services/file.service";
import UserService from "../Services/user.service";
import './styles.css';
import { ResourceCard } from "./ResourceCard";
import MyProfile from "./MyProfile";
import FriendProfile from "./FriendProfile";

const Wrapper = styled('div')({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  width: "75%",
  margin: "10px auto",
  position: "relative",
  '@media (max-width:1199px)': {
    width: "90%",
  },
  '@media (max-width:575px)': {
    width: "100%",
  }
})
const Background = styled('div')({
  width: "100%",
  height: "70px"
})
const Content = styled('div')({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  backgroundColor: "white",
  borderRadius: "6px",
  alignItems: "center",
  padding: "50px 0 10px 0"
})
const Avatar = styled('img')({
  position: "absolute",
  objectFit: "cover",
  width: "140px",
  height: "140px",
  left: 0,
  right: 0,
  margin: "auto",
  borderRadius: "50%"
})
const Name = styled('h2')({
  marginTop: "30px",
  color: "#3C4858",
  fontFamily: '"Roboto Slab", "Times New Roman", serif',
  fontWeight: "700",
  textDecoration: "none",
  minHeight: "32px",
})
const FieldWrapper = styled('button')(({ theme, selected }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  margin: "0 10px",
  color: selected ? "#FFFFFF" : "#555555",
  boxShadow: selected && "0 4px 20px 0px rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(156 39 176 / 40%)",
  backgroundColor: "white",
  borderRadius: "4px",
  flexShrink: 0,
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  border: `1px solid ${selected ? theme.palette.secondary.main : "white"}`,
  boxSizing: "border-box",
  padding: "16px",
  minWidth: "78px",
  minHeight: "82px",
  cursor: !selected && "pointer"
}));
const FieldSelector = styled('div')({
  display: "flex"
})
const FIELDS = [
  {
    name: "movies",
    type: "movie",
    icon: MovieIcon,
    render: ResourceCard
  },
  {
    name: "series",
    type: "serie",
    icon: LiveTvIcon,
    render: ResourceCard
  }
]

const Profile = () => {
  const { userId } = useParams();
  const { user } = useUserContext();
  const theme = useTheme();

  const [currentUser, setCurrentUser] = useState(user);
  const [currentUserAvatar, setCurrentUserAvatar] = useState(null);
  const [myAvatar, setMyAvatar] = useState(null);
  const [selectedField, setSelectedField] = useState(FIELDS[0]);
  const [rankings, setRankings] = useState([]);
  const [selectedResource, setSelectedResource] = useState(null);
  const [rankModalOpen, setRankModalOpen] = useState(false);

  useEffect(() => {
    FileService.getFile(user.avatar)
      .then(resp => setMyAvatar(URL.createObjectURL(resp.data)))
      .catch(err => console.log(err))
  }, [user.avatar]);

  useEffect(() => {
    if(currentUser.id === parseInt(userId)) return;
   
    UserService.getUser(userId)
    .then(resp => {
      setCurrentUser(resp.data);
      return FileService.getFile(resp.data.avatar);
    })
    .then(resp => setCurrentUserAvatar(URL.createObjectURL(resp.data)))
    .catch(err => console.log(err))
   }, [userId, currentUser.id]);

  const handleModalStatus = (status, resource = null) => {
    setSelectedResource(resource)
    return setRankModalOpen(status);
  };

  useEffect(() => {
    RankingService.getRankingByUserIdAndType(currentUser.id, selectedField.type)
      .then(
        response => setRankings(response.data),
        () => toast.error("Failed to load the user's profile")
      )
  }, [selectedField, currentUser.id, rankModalOpen])

  return(
    <Wrapper>
      <Avatar src={isMe(userId, user.id) ? (myAvatar || profileLogo) : (currentUserAvatar || profileLogo)} alt={"Profile"} />
      <Background />
      <Content>
        <Name>{`${currentUser.username.charAt(0).toUpperCase()}${currentUser.username.slice(1)}`}</Name>
        <FieldSelector>
          {
            FIELDS.map(f => (
              <FieldWrapper key={f.type} selected={f.name === selectedField.name} onClick={() => setSelectedField(f)}>
                <f.icon style={{color: theme.palette.secondary.main}} />
                <h4 style={{margin: "10px 0 0 0", color: theme.palette.secondary.main}}>{f.name}</h4>
              </FieldWrapper>
            ))
          }
        </FieldSelector>
        <Divider style={{margin: "20px 0"}} />
        { isMe(userId, user.id) ?
          <MyProfile rankings={rankings} selectedField={selectedField} openRankModal={handleModalStatus}/>:
          <FriendProfile rankings={rankings} selectedField={selectedField} currentUser={currentUser} currentUserAvatar={currentUserAvatar || profileLogo} myAvatar={myAvatar || profileLogo}/>
        }
      </Content>
      <RankModal
        key={selectedField.type}
        selectedResource={selectedResource}
        isOpen={rankModalOpen}
        onDismiss={() => handleModalStatus(false)}
        selectedField={selectedField}
      />
    </Wrapper>
  );
};

export default Profile;
