import React from "react";
import { styled } from "@mui/material/styles";
import {toast} from "react-toastify";
import { Avatar, Button, CssBaseline, TextField, Grid, Typography } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import {Link} from "react-router-dom";
import {ContentLayout} from "../Utils/ui";
import {useNavigate, useLocation} from "react-router-dom";
import AuthService from "../Services/auth.service";

const Wrapper = styled('div')({
  display: "flex",
  flexDirection: "column",
  width: "400px",
  alignItems: "center",
  margin: "auto",
  padding: "30px",
  borderRadius: "6px",
  backgroundColor: "white"
})

const Register = () => {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const [username, setUsername] = React.useState(null);
  const [email, setEmail] = React.useState(location.state?.email && location.state?.email);
  const [password, setPassword] = React.useState(null);

  const onRegister = (e) => {
    e.preventDefault();
    AuthService.register(username, email, password)
      .then(
        () => { navigate(`/login`) },
        error => toast.error(error.response.data.message)
      )
  }

  return(
    <ContentLayout>
      <Wrapper>
        <CssBaseline />
        <Avatar style={{backgroundColor: theme.palette.secondary.main}}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form onSubmit={(e) => onRegister(e)} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="username"
            label="Username"
            name="username"
            autoComplete="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            style={{ margin: "24px 0px 16px" }}
          >
            Sign Up
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link to={"/login"} variant="body2">
                {"Already have an account ? Sign in."}
              </Link>
            </Grid>
          </Grid>
        </form>
      </Wrapper>
    </ContentLayout>
  );
};

export default Register;
